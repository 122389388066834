import { h } from 'snabbdom';
import * as domData from './data';
import { lichessClockIsRunning, setClockWidget } from './clock';
import { uciToMove } from 'chessground/util';
import { Chessground as makeChessground } from 'chessground';
export const initMiniBoard = (node) => {
    const [fen, orientation, lm] = node.getAttribute('data-state').split(',');
    initMiniBoardWith(node, fen, orientation, lm);
};
export const initMiniBoardWith = (node, fen, orientation, lm) => {
    domData.set(node, 'chessground', makeChessground(node, {
        orientation,
        coordinates: false,
        viewOnly: !node.getAttribute('data-playable'),
        fen,
        lastMove: uciToMove(lm),
        drawable: {
            enabled: false,
            visible: false,
        },
    }));
};
export const initMiniBoards = (parent) => Array.from((parent || document).getElementsByClassName('mini-board--init')).forEach((el) => {
    el.classList.remove('mini-board--init');
    initMiniBoard(el);
});
export const fenColor = (fen) => (fen.includes(' w') ? 'white' : 'black');
export const renderClock = (color, time) => h(`span.mini-game__clock.mini-game__clock--${color}`, {
    attrs: { 'data-time': time, 'data-managed': 1 },
});
export const initMiniGame = (node, withCg) => {
    const [fen, color, lm] = node.getAttribute('data-state').split(','), config = {
        coordinates: false,
        viewOnly: true,
        fen,
        orientation: color,
        lastMove: uciToMove(lm),
        drawable: {
            enabled: false,
            visible: false,
        },
    }, $el = $(node).removeClass('mini-game--init'), $cg = $el.find('.cg-wrap'), turnColor = fenColor(fen);
    domData.set($cg[0], 'chessground', (withCg ?? makeChessground)($cg[0], config));
    ['white', 'black'].forEach((color) => $el.find('.mini-game__clock--' + color).each(function () {
        setClockWidget(this, {
            time: parseInt(this.getAttribute('data-time')),
            pause: color != turnColor || !lichessClockIsRunning(fen, color),
        });
    }));
    return node.getAttribute('data-live');
};
export const initMiniGames = (parent) => {
    const nodes = Array.from((parent || document).getElementsByClassName('mini-game--init')), ids = nodes.map(x => initMiniGame(x)).filter(id => id);
    if (ids.length)
        site.pubsub.after('socket.connect').then(() => site.socket.send('startWatching', ids.join(' ')));
};
export const updateMiniGame = (node, data) => {
    const lm = data.lm, cg = domData.get(node.querySelector('.cg-wrap'), 'chessground');
    if (cg)
        cg.set({
            fen: data.fen,
            lastMove: uciToMove(lm),
        });
    const turnColor = fenColor(data.fen);
    const updateClock = (time, color) => {
        const clockEl = node?.querySelector('.mini-game__clock--' + color);
        if (clockEl && !isNaN(time))
            setClockWidget(clockEl, {
                time: time,
                pause: color != turnColor || !lichessClockIsRunning(data.fen, color),
            });
    };
    updateClock(data.wc, 'white');
    updateClock(data.bc, 'black');
};
export const finishMiniGame = (node, win) => ['white', 'black'].forEach(color => {
    const clock = node.querySelector('.mini-game__clock--' + color);
    // don't interfere with snabbdom clocks
    if (clock && !clock.dataset['managed'])
        $(clock).replaceWith(`<span class="mini-game__result">${win ? (win === color[0] ? 1 : 0) : '½'}</span>`);
});
